import { request } from 'api'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Endpoint } from '../data/Webhooks.types'
import { debounce } from 'lodash'

interface Props {
  endpoint?: Endpoint
  adjustRunButtonPosition: () => void
}

export const useHandleEndpoint = ({ endpoint, adjustRunButtonPosition }: Props) => {
  const [input, setInput] = useState<Record<string, boolean | string | File>>(endpoint ? endpoint.input.reduce((acc, cv) => 'default' in cv && cv.default !== undefined ? { ...acc, [cv.name]: cv.default } : acc, {}) : {})
  const [output, setOutput] = useState<Record<string, any> | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    type: 'file' | 'boolean' | 'number' | 'string',
    name: string,
    min?: number,
    max?: number,
    maxLength?: number,
    formats?: string[],
    maxSizeInBytes?: number
  ) => {
    if (type === 'file') {
      const files = e.target.files

      if (files && files.length > 0) {
        const file = files[0]
        if (maxSizeInBytes && file.size > maxSizeInBytes) {
          alert(`Uploaded file is too big.\nMax allowed size is ${maxSizeInBytes > 1048576 ? `${(maxSizeInBytes / (1024 * 1024)).toFixed(2)}MB` : `${(maxSizeInBytes / 1024).toFixed(2)}KB`}.`)
          return
        }
        if (formats && !formats.includes(file.type)) {
          alert(`Uploaded file type is not allowed.\nAllowed types: ${formats}.`)
          return
        }
        setInput(inputValues => ({ ...(inputValues ?? {}), [name]: file }))
      }
    }

    if (type === 'boolean') {
      const value = e.target.checked
      setInput(inputValues => ({ ...(inputValues ?? {}), [name]: value }))
    }

    if (type === 'number') {
      const value = e.target.value

      if ((min && Number(value) < min) || (max && Number(value) > max)) return
      setInput(inputValues => ({ ...(inputValues ?? {}), [name]: value }))
    }

    if (type === 'string') {
      const value = e.target.value

      if (value.length > Number(maxLength)) return
      setInput(inputValues => ({ ...(inputValues ?? {}), [name]: value }))
    }
  }

  const runEndpoint = useCallback(async () => {
    if (Object.keys(input).length === 0) return
    setOutput(undefined)
    setLoading(true)

    const body = new FormData()
    Object.keys(input).forEach(name => {
      const value = typeof input[name] === 'boolean' ? String(input[name]) : input[name]
      body.append(name, value as string | Blob)
    })

    const data = await request<Record<string, any>>({
      url: `${process.env.REACT_APP_SERVICES_BASE}${endpoint?.path}${endpoint?.queryParams ? `?${new URLSearchParams(endpoint.queryParams).toString()}` : ''}`,
      method: 'POST',
      requestOptions: { body }
    })

    setOutput(data)
    setLoading(false)

    setTimeout(() => adjustRunButtonPosition(), 100)
  }, [adjustRunButtonPosition, endpoint?.path, endpoint?.queryParams, input])

  return { input, loading, output, handleOnChange, runEndpoint }
}

export const useAdjustSplitter = () => {
  const [isAdjustingRunButtonPosition, setIsAdjustingRunButtonPosition] = useState(false)

  const endpointInputRef = useRef<HTMLDivElement>(null)
  const runButtonRef = useRef<HTMLButtonElement>(null)

  const handleDebouncedSetIsAdjustingbuttonPosition = debounce((isAdjustingRunButtonPosition: boolean) => {
    setIsAdjustingRunButtonPosition(isAdjustingRunButtonPosition)
  }, 500)

  const adjustRunButtonPosition = useCallback(() => {
    if (runButtonRef.current && endpointInputRef.current) {
      runButtonRef.current.style.left = `${endpointInputRef.current.offsetWidth - 19}px`
      runButtonRef.current.style.top = `${endpointInputRef.current.offsetHeight / 2 - 22}px`
    }
  }, [])

  const handleMeasuredSizesChanged = () => {
    handleDebouncedSetIsAdjustingbuttonPosition(false)
  }

  useEffect(() => {
    adjustRunButtonPosition()
    window.addEventListener('resize', adjustRunButtonPosition)

    return () => {
      window.removeEventListener('resize', adjustRunButtonPosition)
    }
  }, [adjustRunButtonPosition])

  return { endpointInputRef, runButtonRef, isAdjustingRunButtonPosition, adjustRunButtonPosition, setIsAdjustingRunButtonPosition, handleMeasuredSizesChanged }
}
