/* eslint-disable operator-linebreak */
import { LoadingSpinner, SettingsHeader, SettingsPageStructure, SettingsStructureRightFilters, UserGroup as UserGroupIcon } from '@reply-pro/component-library'
import AstronautEmptyState from 'components/AstronautEmptyState'
import AddUserGroup from './AddUserGroup'
import { useState } from 'react'
import UserGroup from './UserGroup'
import NoResultsCaption from 'components/NoResultsCaption'
import { userGroup } from 'types/user'
import AddUsersToUserGroup from './AddUsersToUserGroup'
import { useGetUserGroupUser, useGetUserGroups } from './hooks'
import UserGroupUser from './UserGroupUser'
import './UserGroups.scss'

const UserGroups = () => {
  const [selectedUserGroup, setSelectedUserGroup] = useState<userGroup>()
  const { data: dataUserGroups, searchValue: searchValueUserGroups, loading: loadingUserGroups, searchUserGroups, clearSearch: clearUserGroupsSearch } = useGetUserGroups(selectedUserGroup, setSelectedUserGroup)
  const { data: dataUserGroupUsers, searchValue: searchValueUserGroupUsers, loading: loadingUserGroupUsers, searchUsers: searchUserGroupUsers, clearSearch: clearUserGroupUsersSearch } = useGetUserGroupUser(selectedUserGroup?.id)

  return (
    <SettingsPageStructure
      settingsHeader={
        <SettingsHeader
          title='User Groups'
          icon={<UserGroupIcon className='icon-title' />}
          button={<AddUserGroup searchValueUserGroups={searchValueUserGroups} />}
        />
      }
      subHeaderTitles={(dataUserGroups && dataUserGroups.length > 0) || searchValueUserGroups || loadingUserGroups ?
          ({
            mainSubTitle: (
              <SettingsStructureRightFilters
                elementSelected={selectedUserGroup?.name ?? 'User Group'}
                elementSelectedDescription={selectedUserGroup?.description}
                searchPlaceholder='Search Users'
                updateQuery={searchUserGroupUsers}
                searchParam={searchValueUserGroupUsers}
                clearQuery={clearUserGroupUsersSearch}
              >
                <AddUsersToUserGroup userGroupId={selectedUserGroup?.id} searchValueUserGroupUsers={searchValueUserGroupUsers} userGroupUsers={dataUserGroupUsers} />
              </SettingsStructureRightFilters>
            ),
            search: searchUserGroups,
            searchValue: searchValueUserGroups,
            clearSearch: clearUserGroupsSearch,
            placeHolderSearch: 'Search User Groups'
          }) :
        undefined}
      asideChildren={
        loadingUserGroups ?
          <div className='loading-spinner-container'><LoadingSpinner /></div> :
          dataUserGroups && dataUserGroups.length > 0 ?
            <div className='user-groups-container'>
              {dataUserGroups.map(userGroup =>
                <UserGroup
                  key={userGroup.node.id}
                  userGroup={userGroup}
                  active={userGroup.node.id === selectedUserGroup?.id}
                  onClick={() => setSelectedUserGroup(userGroup.node)}
                  count={userGroup?.node?.userCount || 0}
                />)}
            </div> :
            searchValueUserGroups ?
              <NoResultsCaption className='user-group-no-results' /> :
              <AstronautEmptyState copy='You have no User Group yet!' />
      }
      childrenClassName='user-group-user-container'
    >
      {
        (searchValueUserGroups && !selectedUserGroup) || loadingUserGroupUsers || (loadingUserGroups && searchValueUserGroups === '') ?
          <div className='loading-spinner-container'><LoadingSpinner /></div> :
          selectedUserGroup && dataUserGroupUsers && dataUserGroupUsers.length > 0 ?
            dataUserGroupUsers.map(userGroupUser =>
              <UserGroupUser
                key={userGroupUser.node.id}
                id={selectedUserGroup?.id}
                user={userGroupUser.node}
              />) :
            searchValueUserGroupUsers ?
              <NoResultsCaption className='user-group-no-results' /> :
              <AstronautEmptyState
                copy={dataUserGroups && dataUserGroups.length === 0 ? 'You have no User Groups yet!' : 'The selected User Group has no Users yet!'}
              />
      }
    </SettingsPageStructure>
  )
}

export default UserGroups
