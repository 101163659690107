import { PayloadAction } from '@reduxjs/toolkit'
import AccountsState from './accountsTypes'
import usersState from './users/types.users'

export const updateNotificationsInData = (state: AccountsState, action: PayloadAction<string, string, { arg: string; requestId: string; requestStatus: 'fulfilled' }>) => {
  const updatedState = { ...state }

  const accountId = action.meta.arg
  const accountIndex = updatedState.accounts.data?.accounts.edges.findIndex((edge) => edge.node.id === accountId)

  if (accountIndex !== undefined && accountIndex !== -1 && updatedState.accounts.data) {
    updatedState.accounts.data = {
      ...updatedState.accounts.data,
      accounts: {
        ...updatedState.accounts.data.accounts,
        edges: [...(updatedState.accounts.data.accounts.edges || [])]
      }
    }

    const account = updatedState.accounts.data.accounts.edges[accountIndex]?.node

    if (account) {
      updatedState.accounts.data.accounts.edges = [
        ...updatedState.accounts.data.accounts.edges.slice(0, accountIndex),
        { node: { ...account, notificationsDeactivated: !account.notificationsDeactivated } },
        ...updatedState.accounts.data.accounts.edges.slice(accountIndex + 1)
      ]
    }
  }

  return updatedState.accounts.data
}

export const updateUserAllowLoginInData = (
  state: usersState,
  action: PayloadAction<string, string, { arg: string; requestId: string; requestStatus: 'fulfilled' }>
) => {
  const updatedState = { ...state }

  const userId = action.meta.arg
  const userIndex = updatedState.allUsers.data?.users.edges.findIndex((edge) => edge.node.id === userId)

  if (userIndex !== undefined && userIndex !== -1 && updatedState.allUsers.data) {
    updatedState.allUsers.data = {
      ...updatedState.allUsers.data,
      users: {
        ...updatedState.allUsers.data.users,
        edges: [...(updatedState.allUsers.data.users.edges || [])]
      }
    }

    const user = updatedState?.allUsers?.data?.users?.edges[userIndex]?.node

    if (user) {
      updatedState.allUsers.data.users.edges = [
        ...updatedState.allUsers.data.users.edges.slice(0, userIndex),
        { node: { ...user, allowLogin: !user.allowLogin } },
        ...updatedState.allUsers.data.users.edges.slice(userIndex + 1)
      ]
    }
  }

  return updatedState.allUsers.data
}
