import { gql } from '@apollo/client'

export const ACCOUNTS_LIST = gql`
  query accounts($first: Int, $name: String) {
    accounts(first: $first, name: $name) {
      edges {
        node {
          id
          name
          remoteId
          features
          notificationsDeactivated
          parent {
            id
            name
          }
          children {
            edges {
              node {
                id
                name
              }
            }
          }
          searchApiKey
        }
      }
    }
  }
`

export const TOGGLE_FEATURE_MAINTENANCE = gql`
  mutation featureMaintenanceToggle($input: FeatureMaintenanceToggleInput!) {
    featureMaintenanceToggle(input: $input) {
      created {
        id
      }
      errors {
        message
        code
      }
      modified {
        id
        data
      }
      success
    }
  }
`

export const FEATURE_MAINTENANCE = gql`
  query {
    reporting: featureMaintenance(feature: "Reporting") {
      feature
      isMaintenance
      status
      message
    }
    inbox: featureMaintenance(feature: "Inbox") {
      feature
      isMaintenance
      status
      message
    }
    activities: featureMaintenance(feature: "Activities") {
      feature
      isMaintenance
      status
      message
    }
  }
`

export const DUMB_QUERY = gql`
  query file {
    file
  }
`

export const FILES = (files: string[]) => {
  const queries = files.reduce(
    (accumulator, currentValue, index) =>
      accumulator +
      `file${index + 1}: file(id: "${currentValue}") {
          uuid
          name
          original {
            publicUrl
          }
        } `,
    ''
  )
  return gql`query files {
    ${queries}
  }`
}

export const WHITE_LABEL = gql`
  query whiteLabel($account: GlobalID!) {
    whiteLabel(account: $account) {
      novuIdentifier
      colors
      logos
      paragraphs
      domain
    }
  }
`

export const ACCOUNT_ADD_COLOR = gql`
  mutation accountAddColor($input: AccountAddColorInput!) {
    accountAddColor(input: $input) {
      created {
        id
      }
      errors {
        message
        code
      }
      modified {
        id
        data
      }
      success
    }
  }
`

export const ACCOUNT_ADD_LOGO = gql`
  mutation accountAddLogo($input: AccountAddLogoInput!) {
    accountAddLogo(input: $input) {
      created {
        id
      }
      errors {
        message
        code
      }
      modified {
        id
        data
      }
      success
    }
  }
`

export const ACCOUNT_ADD_PARAGRAPH = gql`
  mutation accountAddParagraph($input: AccountAddParagraphInput!) {
    accountAddParagraph(input: $input) {
      created {
        id
      }
      errors {
        message
        code
      }
      modified {
        id
        data
      }
      success
    }
  }
`

export const ACCOUNT_ADD_NOVU_IDENTIFIER = gql`
  mutation accountAddNovuIdentifier($input: AccountAddNovuIdentifierInput!) {
    accountAddNovuIdentifier(input: $input) {
      created {
        id
      }
      errors {
        message
        code
      }
      modified {
        id
        data
      }
      success
    }
  }
`

export const ACCOUNT_ADD_DOMAIN = gql`
  mutation accountAddDomain ($input: AccountAddDomainInput!) {
      accountAddDomain (input: $input) {
          success
          modified  {
              id
              data
          }
      }
  }
`
