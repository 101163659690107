import { useMutation } from '@apollo/client'
import { Dispatch, SetStateAction } from 'react'
import { ACCOUNT_SET_UP } from 'requests/Settings'
import { newAccountInitialState } from './utils'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { setAccount } from 'store/accountsSlice/accountsDispatchers'
import { addAccountTrackingEvent } from 'utils/dataTracking/accountsTracking'

export type formDataType = {
  name: string,
  legacyId?: number
  timeZone: string
  features: string[]
}

type useHandleAddUserProps = {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  setAccountSelected: Dispatch<SetStateAction<string | undefined>>
  setFormData: Dispatch<SetStateAction<formDataType>>
  setIsAddNewAccountModalOpen: (value: SetStateAction<boolean>) => void
  formData: formDataType
}
export const useHandleAddAccount = ({ setIsAddNewAccountModalOpen, setAccountSelected, setFormData, formData, setIsModalOpen }: useHandleAddUserProps) => {
  const [accountSetUp, { loading }] = useMutation(ACCOUNT_SET_UP)
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const handleAddAccount = async () => {
    try {
      const { data: userAccount } = await accountSetUp({ variables: { input: { name: formData.name, legacyId: formData.legacyId, timeZone: formData.timeZone, features: formData.features.map((feature) => `${feature}/*`) } } })
      if (userAccount?.accountSetUp?.created?.id) {
        addAccountTrackingEvent(formData.name)
        const newAccountId = userAccount.accountSetUp.created.id
        dispatch(setAccount(newAccountId))
        setAccountSelected(formData.name)
        navigate('/settings/')

        setFormData(newAccountInitialState)
        setIsAddNewAccountModalOpen(false)
        setIsModalOpen(false)
      } else {
        console.log('Unable to obtain an ID from the server.')
      }
    } catch (error) {
      console.error('Error adding account:', error)
    }
  }

  return { handleAddAccount, loading }
}
