import { isEqual } from 'lodash'

export const addElementToArrayIfNotAdded = (prev: Array<any>, element: any): any[] => {
  if (element && prev.findIndex((item) => isEqual(item, element)) === -1) {
    return [...prev, element]
  }
  return [...prev]
}

export const removeElementFromArray = (prev: Array<any>, element: any): any[] => {
  return prev.filter((item) => !isEqual(item, element))
}
