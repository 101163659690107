import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { ACCOUNT_SET_FEATURES } from 'requests/Settings'
import { RootState } from 'store'
import { features } from './AddNewAccountModal/utils'
type InitialDataProps = ({ id: string, name?: string, searchApiKey?: string, notificationsDeactivated?: boolean, features?: string[] })

export const useHandleSelectedFeatureAccount = () => {
  const [accountSetFeatures, { data }] = useMutation(ACCOUNT_SET_FEATURES)

  const handleFeatureSelected = (inputValue: {id: string, features: string[]}) => {
    accountSetFeatures({ variables: { input: inputValue } })
  }

  return { handleFeatureSelected, data }
}

export const useHandleFeatureAccount = () => {
  const accountsData = useSelector((state: RootState) => state.accounts.accounts.data?.accounts?.edges)
  const loading = useSelector((state: RootState) => state.accounts.accounts.loading)
  const [updatedAccountData, setUpdatedAccountData] = useState<InitialDataProps[] | undefined>(!loading ? accountsData?.map((accountData) => { return { id: accountData.node.id, name: accountData.node.name, searchApiKey: accountData.node.searchApiKey, notificationsDeactivated: accountData.node.notificationsDeactivated, features: accountData.node.features } }) : [])
  const { handleFeatureSelected } = useHandleSelectedFeatureAccount()

  const handleClick = (inputValue: {id: string, name?: string, searchApiKey?: string, notificationsDeactivated?: boolean, features?: string[]}) => {
    const featuresWithSlash = inputValue?.features?.map((feature) => !feature.includes('/*') ? `${feature}/*` : feature)
    const filteredFeatures = featuresWithSlash?.filter((element, index, arr) => arr.indexOf(element) === arr.lastIndexOf(element))
    if ((filteredFeatures?.length === Object.keys(features).length)) {
      setUpdatedAccountData(prevObjects => {
        const id = inputValue.id
        const updatedObjects = prevObjects?.map(obj => obj.id === id ? { ...inputValue, features: [] } : obj)
        return updatedObjects
      })
      handleFeatureSelected({ id: inputValue.id, features: [] })
    } else if (featuresWithSlash?.length === 1) {
      setUpdatedAccountData(prevObjects => {
        const id = inputValue.id
        const updatedObjects = prevObjects?.map(obj => obj.id === id ? { ...inputValue, features: Object.keys(features).filter(feature => !featuresWithSlash[0].includes(feature)).map((feature) => !feature.includes('/*') ? `${feature}/*` : feature) } : obj)
        return updatedObjects
      })
      handleFeatureSelected({ id: inputValue.id, features: Object.keys(features).filter(feature => !featuresWithSlash[0].includes(feature)).map((feature) => !feature.includes('/*') ? `${feature}/*`.toLowerCase() : feature.toLowerCase()) })
    } else {
      setUpdatedAccountData(prevObjects => {
        const id = inputValue.id
        const updatedObjects = prevObjects?.map(obj => obj.id === id ? { ...inputValue, features: filteredFeatures?.map((feature) => !feature.includes('/*') ? `${feature}/*` : feature) } : obj)
        return updatedObjects
      })
      handleFeatureSelected({ id: inputValue.id, features: filteredFeatures?.map((feature) => !feature.includes('/*') ? `${feature}/*` : feature)! })
    }
  }

  useEffect(() => {
    setUpdatedAccountData(!loading ? accountsData?.map((accountData) => { return { id: accountData.node.id, name: accountData.node.name, searchApiKey: accountData.node.searchApiKey, notificationsDeactivated: accountData.node.notificationsDeactivated, features: accountData.node.features } }) : [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsData])

  return { handleClick, updatedAccountData }
}

interface UseHandleConfirmationProps {
  initialData: InitialDataProps
  handleConfirm: ((inputValue: InitialDataProps) => void)
}
export const useHandleConfirmation = ({ initialData, handleConfirm } : UseHandleConfirmationProps) => {
  const [data, setData] = useState(initialData)
  const [showModal, setShowModal] = useState(false)

  const onConfirm = () => {
    handleConfirm(data)
    closeModal()
  }

  const openModal = (newData: InitialDataProps) => {
    setData(newData)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    setData(initialData)
  }

  return { onConfirm, openModal, closeModal, showModal }
}
