import { Button, MultiSelectInput, PlusAdd } from '@reply-pro/component-library'
import Modal from 'components/Modal'
import { Dispatch, SetStateAction, createRef, useState } from 'react'
import { useSelectUsersToAdd } from './hooks'
import { useGetUsers } from 'utils/hooks/users'
import { user } from 'types/user'
import SelectedUsers from 'components/UserEditor/UserSelector/SelectedUsers'
import { userNode } from 'store/settingsSlice/settingsTypes'
import './AddUsersToUserGroup.scss'

interface AddUsersToUserGroupProps {
  userGroupId?: string
  searchValueUserGroupUsers?: string
  userGroupUsers?: userNode[]
}

const AddUsersToUserGroup = ({ userGroupId, searchValueUserGroupUsers, userGroupUsers }: AddUsersToUserGroupProps) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Button stroked onClick={() => setShowModal(true)}><PlusAdd />Add User</Button>
      {showModal && <AddUsersToUserGroupModal showModal={showModal} setShowModal={setShowModal} userGroupId={userGroupId} searchValueUserGroupUsers={searchValueUserGroupUsers} userGroupUsers={userGroupUsers} />}
    </>
  )
}

interface AddUsersToUserGroupModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  userGroupId?: string
  searchValueUserGroupUsers?: string
  userGroupUsers?: userNode[]
}

const AddUsersToUserGroupModal = ({ showModal, setShowModal, userGroupId, searchValueUserGroupUsers, userGroupUsers }: AddUsersToUserGroupModalProps) => {
  const { data, loading: loadingUsers, setQueryParam } = useGetUsers()
  const { usersToAdd, addUser, removeUser, handleOnSubmit, loading, error, setUsersToAdd } = useSelectUsersToAdd({ userGroupId, setShowModal, searchValueUserGroupUsers })

  const input = createRef()

  return (
    <Modal
      showModal={showModal}
      title='Add Users to User Group'
      actions={
        <div className='add-user-group-actions'>
          <Button onClick={handleOnSubmit} disabled={loading || !usersToAdd || usersToAdd.length === 0}>
            Add Users
          </Button>
          {error && <p className='error-message'>There has been an error, please try again.</p>}
        </div>
      }
      toggleModalOpen={() => setShowModal(false)}
      className='user-group-modal'
    >
      <div className='add-users-modal-body'>
        <MultiSelectInput
          onAdd={(id) => addUser(data?.users.edges.find(({ node }) => node.id === id)?.node as user)}
          onRemove={removeUser}
          onClear={() => setUsersToAdd([])}
          input={input}
          onInputChange={(e: { target: { value: SetStateAction<string> } }) => setQueryParam(e.target.value)}
          loading={loadingUsers}
          onCollapse={() => setQueryParam('')}
          className='add-users-user-selector'
          values={usersToAdd?.map(user => user.id)}
          label='Select Users'
          noPlaceholder
        >
          {data?.users?.edges.filter(({ node }) => !userGroupUsers?.some(user => user.node.id === node.id)).map(({ node }) =>
            <option value={node.id} key={node.id}>{node.email}</option>)}
        </MultiSelectInput>
        <SelectedUsers
          users={usersToAdd}
          isForAllUsers={false}
          removeUserAction={removeUser}
        />
      </div>
    </Modal>
  )
}

export default AddUsersToUserGroup
