import { gql } from '@apollo/client'
import { request } from 'api'
import { FilterValues, LockedFilter } from 'types/metabase'

export const REPORT_COLLECTIONS = gql`
query reportCollections {
  reportCollections {
    edges {
      node {
        id
        name
        order
      }
    }
  }
}
`

export const REPORT_COLLECTION = gql`
query reportCollection($id: GlobalID!) {
  reportCollection(id: $id) {
    id
    name
    order
    reportSections {
      id
      name
      order
      reportPages {
        id
        link
        remoteId
        embedType
        name
        order
        isForAnyUser
        parameters
        authorized
        users {
          edges {
            node {
              id
              email
              firstName
              lastName
            }
          }
        }
        userGroups {
          edges {
            node {
              id
              name
              description
            }
          }
        }
      }
    }
  }
}
`

export const USERS = gql`
query users($search: String, $all: Boolean, $allowLogin: String) {
  users(search: $search, all: $all allowLogin: $allowLogin){
    edges{
      node {
        id
        uuid
        email
        firstName
        lastName
        isSuperuser
        isEmployee
        timeZone
        allowLogin
      }
    }
  }
}
`

export const TOGGLE_USER_ACTIVE = gql`
mutation userToggleAllowLogin($input: UserToggleAllowLoginInput!) {
  userToggleAllowLogin(input: $input) {
      modified {
          id
      }
      errors {
          message
          code
      }
      success
  }
}
`

export const REPORT_COLLECTION_ADD = gql`
  mutation reportCollectionAdd ($input: ReportCollectionAddInput!) {
    reportCollectionAdd(input: $input) {
      errors {
        message
        code
      }
      success
    }
  }
`

export const REPORT_SECTION_ADD = gql`
  mutation reportSectionAdd  ($input: ReportSectionAddInput!) {
    reportSectionAdd (input: $input) {
      errors {
        message
        code
      }
      success
    }
  }
`

export const REPORT_PAGE_ADD = gql`
  mutation reportPageAdd  ($input: ReportPageAddInput!) {
    reportPageAdd (input: $input) {
      errors {
        message
        code
      }
      success
    }
  }
`

export const REPORT_PAGE_UPDATE = gql`
  mutation reportPageUpdate($input: ReportPageUpdateInput!) {
      reportPageUpdate(input: $input) {
          modified {
              id
          }
          errors {
              code
          }
          success
      }
  }
`

export const REPORT_COLLECTION_REMOVE = gql`
mutation reportCollectionRemove  ($input: ReportCollectionRemoveInput!) {
  reportCollectionRemove (input: $input) {
    errors {
      message
      code
    }
    success
  }
}
`

export const REPORT_SECTION_REMOVE = gql`
mutation reportSectionRemove  ($input: ReportSectionRemoveInput!) {
  reportSectionRemove (input: $input) {
    errors {
      message
      code
    }
    success
  }
}
`

export const REPORT_PAGE_REMOVE = gql`
mutation reportPageRemove  ($input: ReportPageRemoveInput!) {
  reportPageRemove (input: $input) {
    errors {
      message
      code
    }
    success
  }
}
`

export const REPORT_PAGE_SET_USERS = gql`
mutation reportPageSetUsers  ($input: ReportPageSetUsersInput!) {
  reportPageSetUsers (input: $input) {
    errors {
      message
      code
    }
    success
  }
}
`

export const ADD_USER = gql`
mutation userAddNew($input: UserAddNewInput!) {
  userAddNew(input: $input) {
    created {
      id
      data
    }
    errors {
      message
      code
    }
    success
  }
}
`

export const ADD_NEW_USER_TO_ACCOUNT = gql`
mutation userAddNewToAccount($input: UserAddNewToAccountInput!) {
  userAddNewToAccount(input: $input) {
    created {
      id
      data
    }
    errors {
      message
      code
    }
    success
  }
}
`

export const ASSOCIATE_USER_TO_ACCOUNT = gql`
mutation userAssociateToAccount($input: UserAssociateToAccountInput!) {
  userAssociateToAccount(input: $input) {
    created {
      id
    }
    errors {
      message
      code
    }
    success
  }
}
`

export const ACCOUNT_DETAILS = gql`
query account($id: GlobalID!) {
  account(id: $id) {
    id
    remoteId
    name
    searchApiKey
    features
  }
}
`
export const ACCOUNT_SET_FEATURES = gql`
mutation accountSetFeatures($input: AccountSetFeaturesInput!) {
  accountSetFeatures(input: $input) {
    created {
      id
      data
    }
    errors {
      message
      code
    }
    success
  }
}
`
export const ACCOUNT_SET_UP = gql`
mutation accountSetUp($input: AccountSetUpInput!) {
  accountSetUp(input: $input) {
    created {
      id
    }
    errors {
      message
      code
    }
    success
  }
}
`

export const RETRIEVE_CHANNELS = gql`
  query channels(
    $search: String
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $account: GlobalID
    $id: GlobalID
    $type: String
    $isEnabled: Boolean
    $integrationIdentifier: String
  ) {
    channels(
      search: $search
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      account: $account
      id: $id
      type: $type
      isEnabled: $isEnabled
      integrationIdentifier: $integrationIdentifier
    ) {
      edges {
        node {
          id
          uuid
          name
          isEnabled
          attributes
          type
        }
      }
    }
  }
`

export async function getMetabaseLockedFilters (baseUrl: string, dashboardId: string) {
  const queryParams = new URLSearchParams({ base_url: baseUrl })
  return await request<LockedFilter[]>({ path: `/metabase/dashboard/${dashboardId}/locked_params?${queryParams}` })
}

export async function getMetabaseFilterValues (baseUrl: string, dashboardId: string, paramId: string, constraints?: Record<LockedFilter['id'], string[]>) {
  const queryParams = new URLSearchParams({ base_url: baseUrl })
  if (constraints) {
    Object.entries(constraints).forEach(([key, values]) => {
      values.forEach(value => {
        queryParams.append(key, value)
      })
    })
  }

  return await request<FilterValues>({ path: `/metabase/dashboard/${dashboardId}/param/${paramId}/values?${queryParams}` })
}
