import { MultiSelectInput, SelectInput, TextInput } from '@reply-pro/component-library'
import { ChangeEvent, createRef } from 'react'
import { eventTargetStringValue } from '@reply-pro/component-library/dist/components/SettingsStructureRightFilters/SettingsStructureRightFilters.types'
import { useHandleSelectInput } from 'pages/Settings/ManageAccounts/hooks'
import { formConfig } from '../utils'
import './formComponents.scss'
interface FormComponentsProps {
  handleInputChange: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, attribute?: string | undefined) => void
  formData: {
    name: string
    legacyId?: number
    timeZone: string
    features: string[]
  }
  fieldName: string
}
export default function FormComponents ({ formData, handleInputChange, fieldName }: FormComponentsProps) {
  const { handleSearch, handleAddFeatures, handleRemoveFeatures, handleClearFeatures, timeZoneOptions } = useHandleSelectInput(handleInputChange)
  const { placeholder, mandatory, type, title, component: Component, options } = formConfig[fieldName as keyof typeof formConfig]
  const input = createRef()
  return (
    <div className={`${fieldName}-container input-container`}>
      <div className='title'>
        {title}{mandatory ? <span className='mandatory-sign'>*</span> : ''}
      </div>
      {Component === MultiSelectInput &&
        <Component
          values={formData.features || []}
          className={fieldName}
          onAdd={(e) => handleAddFeatures(e, fieldName)}
          onRemove={(e) => handleRemoveFeatures(e, fieldName)}
          onClear={() => handleClearFeatures(fieldName)}
        >
          {Array.isArray(options) && options?.map((option, index) =>
            <option value={option} key={index + '-' + option}>
              {option}
            </option>)}
        </Component>}
      {Component === SelectInput &&
        <Component
          input={input}
          className={fieldName}
          onInputChange={(e: eventTargetStringValue) => handleSearch(e, Object.values(options || []))}
          onChange={(e) => handleInputChange(e as any, fieldName)}
          value={formData.timeZone}
        >
          {(timeZoneOptions || (options && options))?.map((option, index) =>
            <option value={option} key={index + '-' + option}>
              {option}
            </option>)}
        </Component>}
      {Component === TextInput &&
        <Component
          className={fieldName}
          placeholder={placeholder}
          value={formData[fieldName as keyof typeof formConfig]}
          onChange={(e) => handleInputChange(e as any, fieldName)}
          type={type}
        />}
    </div>
  )
}
