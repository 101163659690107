import './manageAccountsModal.scss'
import { SetStateAction, Dispatch, ChangeEvent } from 'react'
import { ArrowRight, Button, NavItem, SearchInput, Clipboard } from '@reply-pro/component-library'
import classNames from 'classnames'
import { useHandleConfirmation, useHandleFeatureAccount } from './hooks'
import ModalWithFilterButtons from 'components/ModalWithFilterButtons'
import { retrieveAccounts, setAccount } from 'store/accountsSlice/accountsDispatchers'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { copyToClipboard } from 'utils/common'
import { features } from './AddNewAccountModal/utils'
import { accountSetFromModalTrackingEvent } from 'utils/dataTracking/accountsTracking'
import ToggleAccountNotifications from './AccountNotifications/ToggleAccountNotifications'
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal'
import { useToggleAccountNotifications } from './AccountNotifications/hooks'

type ManageAccountModalProps = {
  setAccountSelected: Dispatch<SetStateAction<string | undefined>>
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  isModalOpen: boolean
  setIsAddNewAccountModalOpen: Dispatch<SetStateAction<boolean>>
}
export default function ManageAccountsModal ({ setAccountSelected, setIsModalOpen, isModalOpen, setIsAddNewAccountModalOpen }: ManageAccountModalProps) {
  const { handleClick, updatedAccountData } = useHandleFeatureAccount()
  const { handleToggleNotifications } = useToggleAccountNotifications()
  const {
    onConfirm: onFeatureConfirm,
    openModal: openFeatureModal,
    closeModal: closeFeatureModal,
    showModal: showFeatureModal
  } = useHandleConfirmation({
    initialData: { id: '', name: '', searchApiKey: '', notificationsDeactivated: false, features: [''] },
    handleConfirm: handleClick
  })

  const {
    onConfirm: onNotificationConfirm,
    openModal: openNotificationsModal,
    closeModal: closeNotificationsModal,
    showModal: showNotificationModal
  } = useHandleConfirmation({
    initialData: { id: '' },
    handleConfirm: handleToggleNotifications
  })
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const handleSelectedAccount = (id: string, name: string) => {
    accountSetFromModalTrackingEvent(id)
    dispatch(setAccount(id))
    setIsModalOpen(false)
    setAccountSelected(name)
    navigate('/settings/')
  }

  const confirmationModal = {
    Features: <ConfirmationModal showModal={showFeatureModal} warningFirstLine='You are about to enable or disable a Feature for this account.' onConfirm={onFeatureConfirm} confirmText='TOGGLE' buttonText='Confirm' modalTitle='enable/disable feature' toggleModalOpen={closeFeatureModal} />,
    Notifications: <ConfirmationModal showModal={showNotificationModal} warningFirstLine='You are about to enable or disable Notifications for this account.' onConfirm={onNotificationConfirm} confirmText='TOGGLE' buttonText='Confirm' modalTitle='enable/disable notifications' toggleModalOpen={closeNotificationsModal} />
  }
  const filter = _.debounce((e: ChangeEvent<HTMLInputElement>) => {
    dispatch(retrieveAccounts(e.target.value))
  }, 1000)

  return (
    <>
      <ModalWithFilterButtons
        title='MANAGE ALL ACCOUNTS'
        toggleModalOpen={() => setIsModalOpen(false)}
        isShowRelationModal={isModalOpen}
        className='manage-accounts-modal'
        rightTopElement={
          <div className='right-top-elements'>
            <Button onClick={() => setIsAddNewAccountModalOpen(true)}>Add New Account</Button>
          </div>
    }
        otherFilters={
          <SearchInput
            onChange={filter}
            placeholder='Search Account'
          />
      }
      >
        <>
          {updatedAccountData?.map((account, index) =>
            <NavItem className='account-nav-item' key={account.id + '-' + index}>
              <div className='account-wrapper'>
                <div className='account-details'>
                  <div className='account-name'>{account?.name?.toUpperCase()}</div>
                  <div className='account-id-and-buttons'>
                    <div className='account-id-container'>
                      <div className='account-id'>Account ID: {account.id}</div>
                      <div className='clipboard'><Clipboard onClick={() => copyToClipboard(account.id)} /></div>
                    </div>
                    <div className='api-key-container'>
                      <div className='api-key'>Search API Key: {account.searchApiKey}</div>
                      <div className='clipboard'><Clipboard onClick={() => copyToClipboard(account.id)} /></div>
                    </div>
                  </div>
                </div>
                <div className='account-actions'>
                  <div className='features-container'>
                    {Object.entries(features).map(([feature, FeatureIcon], index) =>
                      <Button
                        stroked
                        small
                        className={classNames('feature-container', {
                          'not-allowed': account.features?.length === 1 && account.features[0].toLowerCase().includes(feature.toLowerCase()),
                          disabled: account?.features && account?.features?.length > 0 && !account.features?.some((featureAllowed) => {
                            return (
                              featureAllowed && featureAllowed.toLowerCase().includes(feature.toLowerCase())
                            )
                          }
                          )
                        })}
                        key={feature + '-' + index}
                        onClick={() => !(account.features?.length === 1 && account.features[0].toLowerCase().includes(feature.toLowerCase())) && openFeatureModal({ id: account.id, features: [...account.features!, feature], name: account.name, searchApiKey: account.searchApiKey, notificationsDeactivated: account.notificationsDeactivated })}
                      >
                        <div className='feature-icon-container'><FeatureIcon className='feature-icon' /></div>
                        <div className='feature-name'>
                          {feature}
                        </div>
                      </Button>
                    )}
                    <ToggleAccountNotifications toggleModalOpen={openNotificationsModal} id={account.id} notificationsDeactivated={account.notificationsDeactivated} />
                    <div className='round-buttons'><Button className='select-account-button' onClick={() => handleSelectedAccount(account.id, account.name!)} stroked small><ArrowRight className='button-icon' /></Button></div>
                  </div>
                </div>
              </div>
            </NavItem>
          )}
        </>
      </ModalWithFilterButtons>
      {showFeatureModal && confirmationModal.Features}
      {showNotificationModal && confirmationModal.Notifications}
    </>
  )
}
