import { MultiSelectInput, SelectInput, TextInput, Inbox as InboxIcon, Reports, Settings, ActivitiesIcon } from '@reply-pro/component-library'
import { timeZoneObject } from 'utils/common/timeZoneChoices'

const TYPE_HERE = 'Type here'

export const newAccountInitialState: {
  name: string
  legacyId?: number
  timeZone: string
  features: string[]
  } = {
    name: '',
    legacyId: undefined,
    timeZone: '',
    features: []
  }

export const formConfig = {
  name: { title: 'Account Name', placeholder: TYPE_HERE, mandatory: true, type: 'text', component: TextInput, options: undefined },
  legacyId: { title: 'Legacy ID', placeholder: TYPE_HERE, mandatory: false, type: 'number', component: TextInput, options: undefined },
  timeZone: { title: 'Time Zone', placeholder: TYPE_HERE, mandatory: true, type: 'text', component: SelectInput, options: timeZoneObject },
  features: { title: 'Features', placeholder: TYPE_HERE, mandatory: true, type: 'text', component: MultiSelectInput, options: ['inbox', 'reporting', 'settings'] }
}

export const features = { Inbox: InboxIcon, Reporting: Reports, Activities: ActivitiesIcon, Settings }
