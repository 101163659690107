import React from 'react'
import { ToggleInput, MultiStateSwitch, TextInput, NavigationFolder, Button, MultiSelectInput } from '@reply-pro/component-library'
import './SurveyDetailsSetup.scss'
import { SurveyDetailsTypes } from './hooks'
import PrivacyPolicySetup from './PrivacyPolicySetup'
import SurveyThemeSelector from '../Themes/SurveyThemeSelector'
import { isValidHexColor } from 'utils/common/isValidHexColor'
import { SurveyLocations } from 'types/location'
import { SURVEY } from 'pages/Settings/ManageSurveys/ManageSurveys'

interface SurveyDetailField {
  title?: string | false;
  render?: (() => React.JSX.Element) | false
  toggle?: () => React.JSX.Element
}

interface SurveyDetailsProps {
  handleChange: (key: keyof SurveyDetailsTypes, value: string | boolean) => void;
  surveyDetails: SurveyDetailsTypes
  disabledSaveButton?: boolean
  handleSaveDetails: () => void
  isSavingDetails: boolean
  locations: SurveyLocations | undefined
  loadingLocations: boolean
  onAdd: (optionValue: string) => void
  onRemove: (optionValue: string) => void
  clearSelection: () => void
  disableSetup: boolean
  handleSearch: (e: any) => void
  input: React.RefObject<unknown>
  values: string[]
  surveyType: string
}

const getSurveyFieldDetails: (props: SurveyDetailsProps) => SurveyDetailField[] = ({ surveyType, values, input, handleSearch, onAdd, onRemove, clearSelection, loadingLocations, locations, surveyDetails, handleChange, disabledSaveButton, handleSaveDetails, isSavingDetails, disableSetup }) => ([
  {
    title: `${surveyType} Name (Internal)`,
    render: () => (
      <TextInput
        className='detail-input'
        value={surveyDetails.surveyName}
        onChange={(e) => handleChange('surveyName', e.target.value)}
        disabled={isSavingDetails || disableSetup}
      />
    )
  },
  {
    title: `${surveyType} Brand Color`,
    render: () => (
      <TextInput
        className='detail-input'
        value={surveyDetails.surveyBrandColor}
        error={surveyDetails.surveyBrandColor !== '' && !isValidHexColor(surveyDetails.surveyBrandColor)}
        onChange={(e) => handleChange('surveyBrandColor', e.target.value)}
        disabled={isSavingDetails || disableSetup}
        placeholder='#335F96'
      />
    )
  },
  {
    title: 'Location Tracking',
    render: () => (
      <div className='container-with-description'>
        <MultiStateSwitch
          className='detail-input'
          labels={['Disabled', 'Question', 'Code']}
          values={['disabled', 'question', 'code']}
          value={surveyDetails.locationTracking}
          onOptionClicked={(value) => handleChange('locationTracking', value)}
          disabledOptions={(isSavingDetails || disableSetup) ? ['disabled', 'question', 'code'] : []}
        />
        <div className='description'>
          {surveyDetails.locationTracking === 'question' ? `Customers select their Location in a ${surveyType} Question` : surveyDetails.locationTracking === 'code' && "We'll generate a survey code for each Location"}
        </div>
      </div>
    )
  },
  {
    title: surveyDetails.locationTracking !== 'disabled' && 'Locations',
    render: surveyDetails.locationTracking !== 'disabled' && (() => (
      <MultiSelectInput
        className='detail-input'
        loading={loadingLocations}
        onAdd={onAdd}
        onRemove={onRemove}
        onClear={clearSelection}
        values={values}
        input={input}
        onInputChange={handleSearch}
        disabled={loadingLocations || surveyDetails.locationTracking === 'disabled' || surveyDetails.isAllLocations || disableSetup}
      >
        {!loadingLocations && locations?.locations?.edges?.map((location, index) => <option key={location.node.id + '-' + index} value={location.node.name}>{location.node.name}</option>)}
      </MultiSelectInput>
    )),
    toggle: () => (
      <div className='locations-header'>
        <span className='all-locations'>All Locations</span>
        <ToggleInput
          checked={surveyDetails.isAllLocations}
          onChange={(e) => handleChange('isAllLocations', e.target.checked)}
          disabled={isSavingDetails || surveyDetails.locationTracking === 'disabled' || disableSetup}
        />
      </div>
    )
  },
  {
    title: 'Custom Privacy Policy Footer',
    toggle: () => (
      <ToggleInput
        checked={surveyDetails.customPrivacyPolicyFooter}
        onChange={(e) => handleChange('customPrivacyPolicyFooter', e.target.checked)}
        disabled={isSavingDetails || disableSetup}
      />
    ),
    render: surveyDetails.customPrivacyPolicyFooter && (() => <PrivacyPolicySetup surveyDetails={surveyDetails} handleChange={handleChange} />)
  },
  {
    title: surveyType === SURVEY && 'Save Partially Completed',
    toggle: () => (
      <ToggleInput
        checked={surveyDetails.savePartiallyCompleted}
        onChange={(e) => handleChange('savePartiallyCompleted', e.target.checked)}
        disabled={isSavingDetails || disableSetup}
      />
    )
  },
  {
    title: surveyType === SURVEY && 'Ask Contact Details',
    toggle: () => (
      <ToggleInput
        checked={surveyDetails.askContactDetails}
        onChange={(e) => handleChange('askContactDetails', e.target.checked)}
        disabled={isSavingDetails || disableSetup}
      />
    )
  },
  {
    render: () => (
      <NavigationFolder
        label={`${surveyType} ${surveyType === SURVEY ? 'Theme' : 'Style'}`}
        className='survey-styles-container'
        large
        rightAngled
        defaultExpanded
      >
        <SurveyThemeSelector selectedTheme={surveyDetails.selectedTheme} handleChange={handleChange} surveyType={surveyType} />
      </NavigationFolder>
    )
  },
  {
    render: () => (
      <div className='save-button-container'>
        <Button onClick={handleSaveDetails} disabled={loadingLocations || disabledSaveButton || isSavingDetails || disableSetup}>{disableSetup ? 'Edit is currently not allowed' : 'Save Details'}</Button>
      </div>
    )
  }
]
)

export default getSurveyFieldDetails
