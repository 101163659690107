import { Button } from '@reply-pro/component-library'
import './addNewAccountModal.scss'
import { formConfig, newAccountInitialState } from './utils'
import { ChangeEvent, SetStateAction, Dispatch, useEffect } from 'react'
import Modal from 'components/Modal'
import { formDataType, useHandleAddAccount } from './hooks'
import FormComponents from './FormComponents'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
interface AddUserModalProps {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  handleInputChange: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, attribute?: string, defaultValue?: string) => void
  isAddNewAccountModalOpen: boolean
  setFormData: Dispatch<SetStateAction<formDataType>>
  setIsAddNewAccountModalOpen: (value: SetStateAction<boolean>) => void
  formData: formDataType
  setAccountSelected: Dispatch<SetStateAction<string | undefined>>
}
export default function AddNewAccountModal ({ setAccountSelected, setIsModalOpen, setIsAddNewAccountModalOpen, formData, handleInputChange, isAddNewAccountModalOpen, setFormData }: AddUserModalProps) {
  const { handleAddAccount, loading } = useHandleAddAccount({ setIsAddNewAccountModalOpen, setAccountSelected, setFormData, formData, setIsModalOpen })
  const defaultTimeZone = useSelector((state: RootState) => state.metadata.user.timeZone)
  useEffect(() => {
    setFormData({ ...newAccountInitialState, timeZone: defaultTimeZone || '' })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      actions={
        <Button
          onClick={handleAddAccount}
          disabled={loading || Object.values(formData).some((value) => {
            return (
              ((typeof value === 'string' && value.length < 3) || (Array.isArray(value) && value.length === 0)))
          }
          )} small
        >Add New Account
        </Button>
      }
      title='ADD NEW ACCOUNT'
      toggleModalOpen={() => setIsAddNewAccountModalOpen(false)}
      showModal={isAddNewAccountModalOpen}
    >
      <div className='add-user-form'>
        {Object.keys(formConfig).map((fieldName) => {
          return (
            <FormComponents key={fieldName} handleInputChange={handleInputChange} formData={formData} fieldName={fieldName} />
          )
        })}
      </div>
    </Modal>
  )
}
