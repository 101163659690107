import { ChangeEvent, useState } from 'react'
import { newAccountInitialState } from './ManageAccountsModal/AddNewAccountModal/utils'
import { addElementToArrayIfNotAdded, removeElementFromArray } from 'utils/common/arraysHandling'
import { eventTargetStringValue } from '@reply-pro/component-library/dist/components/SettingsStructureRightFilters/SettingsStructureRightFilters.types'

export const useHandleAddAccountInput = () => {
  const [formData, setFormData] = useState(newAccountInitialState)
  const [isAddNewAccountModalOpen, setIsAddNewAccountModalOpen] = useState(false)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, attribute?: string) => {
    setFormData((prevAttributes) => ({
      ...prevAttributes,
      [`${attribute}`]: e?.target?.value
    }))
  }

  return { formData, handleInputChange, setFormData, isAddNewAccountModalOpen, setIsAddNewAccountModalOpen }
}

export const useHandleSelectInput = (handleInputChange: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, attribute?: string | undefined) => void) => {
  const [timeZoneOptions, setTimeZoneOptions] = useState<string[]>()
  const [selectedFeature, setSelectedFeature] = useState<string[]>([])

  const handleSearch = (e: eventTargetStringValue, options: string[]) => {
    const filtered = options.filter(
      (value) =>
        value.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setTimeZoneOptions(filtered)
  }
  const handleAddFeatures = (e: string, fieldName: string) => {
    const addElement = addElementToArrayIfNotAdded(selectedFeature, e)
    setSelectedFeature(addElement)
    handleInputChange({ target: { value: addElement } } as any, fieldName)
  }
  const handleRemoveFeatures = (e: string, fieldName: string) => {
    const removeElement = removeElementFromArray(selectedFeature, e)
    setSelectedFeature(removeElement)
    handleInputChange({ target: { value: removeElement } } as any, fieldName)
  }
  const handleClearFeatures = (fieldName: string) => {
    setSelectedFeature([])
    handleInputChange({ target: { value: [] } } as any, fieldName)
  }

  return { handleSearch, handleAddFeatures, handleRemoveFeatures, handleClearFeatures, timeZoneOptions }
}
