import { Button } from '@reply-pro/component-library'
import './manageAccounts.scss'
import ManageAccountsModal from './ManageAccountsModal'
import AccountSetter from 'components/AccountSetter/AccountSetter'
import AddNewAccountModal from './ManageAccountsModal/AddNewAccountModal'
import { useHandleAddAccountInput } from './hooks'
import { useState } from 'react'

export default function ManageAccounts () {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { formData, handleInputChange, setFormData, setIsAddNewAccountModalOpen, isAddNewAccountModalOpen } = useHandleAddAccountInput()
  const [accountSelected, setAccountSelected] = useState<string | undefined>()
  const openModal = () => {
    setIsModalOpen(true)
  }
  return (
    <>
      <div className='manage-accounts-wrap'>
        <div className='select-account-container'>
          <AccountSetter accountSelected={accountSelected} setAccountSelected={setAccountSelected} />
        </div>
        <div className='manage-accounts-button-container'>
          <Button onClick={openModal} stroked className='manage-accounts-button'>
            Manage accounts
          </Button>
        </div>
      </div>
      <AddNewAccountModal
        formData={formData}
        handleInputChange={handleInputChange}
        isAddNewAccountModalOpen={isAddNewAccountModalOpen}
        setIsAddNewAccountModalOpen={setIsAddNewAccountModalOpen}
        setIsModalOpen={setIsModalOpen}
        setFormData={setFormData}
        setAccountSelected={setAccountSelected}
      />
      <ManageAccountsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setIsAddNewAccountModalOpen={setIsAddNewAccountModalOpen}
        setAccountSelected={setAccountSelected}
      />
    </>
  )
}
